@layer packages {
    .range :global(:root) {
        --track-bg: ;
    }

    .range {
        padding: calc(var(--globals-baseUnit) * 2) 0 0 0;
    }

    .rangeTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 0 calc(var(--globals-baseUnit) * 1) 0;
    }

    .rangeTitleText {
        color: var(--colors-gray-700);
        width: 100%;
    }

    .rangeTitleTextHidden {
        visibility: hidden;
    }

    .rangeTrack {
        width: calc(100% - 3.2rem);
        margin-left: calc(var(--globals-baseUnit) * 2);
        flex-grow: 1;
        display: flex;
    }

    .rangeTrackContent {
        width: 100%;
        height: 18px;
        border-radius: 100px;
        align-self: center;
        background: var(--track-bg);
    }

    .rangeThumb {
        height: 3.2rem;
        width: 3.2rem;
        background-color: var(--colors-primary);
        border: 1px solid #23232d;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        outline: none;

        &:after {
            display: block;
            content: "";
            position: absolute;
            border-left: 1px solid var(--colors-secondary);
            height: 2rem;
            bottom: 100%;
            left: calc(50% - 1px);
        }
    }

    .rangeThumbLabel {
        position: absolute;
        white-space: nowrap;
        bottom: calc(100% + 2rem);
    }
}
@layer packages {
    .range :global(:root) {
        --track-bg: ;
    }

    .range {
        padding: calc(var(--globals-baseUnit) * 2) 0 0 0;
    }

    .rangeTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 0 calc(var(--globals-baseUnit) * 1) 0;
    }

    .rangeTitleText {
        color: var(--colors-gray-700);
        width: 100%;
    }

    .rangeTitleTextHidden {
        visibility: hidden;
    }

    .rangeTrack {
        width: calc(100% - 3.2rem);
        margin-left: calc(var(--globals-baseUnit) * 2);
        flex-grow: 1;
        display: flex;
    }

    .rangeTrackContent {
        width: 100%;
        height: 18px;
        border-radius: 100px;
        align-self: center;
        background: var(--track-bg);
    }

    .rangeThumb {
        height: 3.2rem;
        width: 3.2rem;
        background-color: var(--colors-primary);
        border: 1px solid #23232d;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        outline: none;

        &:after {
            display: block;
            content: "";
            position: absolute;
            border-left: 1px solid var(--colors-secondary);
            height: 2rem;
            bottom: 100%;
            left: calc(50% - 1px);
        }
    }

    .rangeThumbLabel {
        position: absolute;
        white-space: nowrap;
        bottom: calc(100% + 2rem);
    }
}
