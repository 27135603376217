@layer packages {
    .dsTooltip {
        display: inline-flex;
    }

    .message {
        padding: calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 4);
        position: relative;
        max-width: 30rem;
    }

    .closeIcon {
        cursor: pointer;
        position: absolute;
        top: calc(var(--globals-baseUnit) * 1);
        right: calc(var(--globals-baseUnit) * 1);
    }
}
@layer packages {
    .dsTooltip {
        display: inline-flex;
    }

    .message {
        padding: calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 4);
        position: relative;
        max-width: 30rem;
    }

    .closeIcon {
        cursor: pointer;
        position: absolute;
        top: calc(var(--globals-baseUnit) * 1);
        right: calc(var(--globals-baseUnit) * 1);
    }
}
