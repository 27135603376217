@layer packages {
    .inputStyle {
        color: var(--colors-secondary);
        opacity: 1;
        border: 0;
        background: transparent;
        width: 100%;
        padding: 1px 2px; /* normalize to chrome styles */

        outline: 0;
    }

    .inputStyle::placeholder {
        color: var(--colors-gray-700);
    }

    .inputStyle:focus {
        outline: 0;
    }

    .inputStyle[type="file"]::file-selector-button {
        right: 3.6rem;
        position: absolute;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: var(--globals-text-family);
        border: none;
    }

    .inputStyle[type="file"]::file-selector-button:before {
        content: "$$$$$$$$$$$";
        position: absolute;
    }

    .multiline {
        resize: none;
    }

    .disabled {
        color: var(--colors-gray-700);
    }
}
@layer packages {
    .inputStyle {
        color: var(--colors-secondary);
        opacity: 1;
        border: 0;
        background: transparent;
        width: 100%;
        padding: 1px 2px; /* normalize to chrome styles */

        outline: 0;
    }

    .inputStyle::placeholder {
        color: var(--colors-gray-700);
    }

    .inputStyle:focus {
        outline: 0;
    }

    .inputStyle[type="file"]::file-selector-button {
        right: 3.6rem;
        position: absolute;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: var(--globals-text-family);
        border: none;
    }

    .inputStyle[type="file"]::file-selector-button:before {
        content: "$$$$$$$$$$$";
        position: absolute;
    }

    .multiline {
        resize: none;
    }

    .disabled {
        color: var(--colors-gray-700);
    }
}
