.inputFileStyle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.fileNameStyle {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.selectFileInfo {
    white-space: nowrap;
    padding-left: calc(2 * var(--globals-baseUnit));
    cursor: pointer;
}

.placeholder {
    position: relative;
    width: 100%;
    flex-basis: 100%;
    cursor: pointer;
}

.text {
    padding-left: calc(1.5 * var(--globals-baseUnit));
    padding-right: calc(1.5 * var(--globals-baseUnit));
}

.inputWrapperStyle {
    position: relative;
}

.rightElementStyle {
    cursor: pointer;
}
.inputFileStyle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.fileNameStyle {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.selectFileInfo {
    white-space: nowrap;
    padding-left: calc(2 * var(--globals-baseUnit));
    cursor: pointer;
}

.placeholder {
    position: relative;
    width: 100%;
    flex-basis: 100%;
    cursor: pointer;
}

.text {
    padding-left: calc(1.5 * var(--globals-baseUnit));
    padding-right: calc(1.5 * var(--globals-baseUnit));
}

.inputWrapperStyle {
    position: relative;
}

.rightElementStyle {
    cursor: pointer;
}
