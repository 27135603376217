@layer packages {
    .defaultContentStyle {
        position: absolute;
        background: #ffffff;
        overflow: auto;
        border-radius: calc(var(--globals-baseUnit) * 2);
        outline: none;
        padding: 2rem;
    }
    .overlay {
        position: fixed;
        inset: 0;
        background-color: rgba(from var(--colors-secondary) R G B / 0.75);
        width: 100%;
    }

    .childrenHolder {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 100%;
        height: 100%;
    }
}

@layer packages {
    .defaultContentStyle {
        position: absolute;
        background: #ffffff;
        overflow: auto;
        border-radius: calc(var(--globals-baseUnit) * 2);
        outline: none;
        padding: 2rem;
    }
    .overlay {
        position: fixed;
        inset: 0;
        background-color: rgba(from var(--colors-secondary) R G B / 0.75);
        width: 100%;
    }

    .childrenHolder {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 100%;
        height: 100%;
    }
}

