@layer rp {
    .leadModalPropertyPlanImage {
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    .leadModalPropertyPlanPlaceholder {
        height: auto;
        width: 100%;
    }

    .leadModalPropertyVendorImage {
        width: auto;
        height: 100%;
        max-width: 100%;
    }

    .leadModalPropertyVendorPlaceholder {
        transform: translate(-50%, -50%) scale(1);
    }
}
@layer rp {
    .leadModalPropertyPlanImage {
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    .leadModalPropertyPlanPlaceholder {
        height: auto;
        width: 100%;
    }

    .leadModalPropertyVendorImage {
        width: auto;
        height: 100%;
        max-width: 100%;
    }

    .leadModalPropertyVendorPlaceholder {
        transform: translate(-50%, -50%) scale(1);
    }
}
