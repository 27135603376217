@layer packages {
    .headerPaddingDefault {
        padding: 2rem 5.2rem 0 2rem;
        margin: 0 0 1.95rem 0;

        @media (min-width: 1024px) {
            margin: 0 0 0.45rem 0;
        }
    }
    .headerPaddingSpacious {
        padding: 2.95rem 5.2rem 0 2rem;
        margin: 0 0 2.8rem 0;

        @media (min-width: 1024px) {
            padding: 2.05rem 5.2rem 0 2rem;
        }
    }

    .headerPaddingMedium {
        padding: calc(var(--globals-baseUnit) * 4) calc(var(--globals-baseUnit) * 3) 0 calc(var(--globals-baseUnit) * 3);
        margin: 0 0 calc(var(--globals-baseUnit) * 3) 0;
    }

    .headerPaddingFit {
        padding: calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 1.5) 0 calc(var(--globals-baseUnit) * 1.5);
        margin: 0 0 var(--globals-baseUnit) 0;
    }

    .headerPaddingFitGray {
        padding: calc(var(--globals-baseUnit) * 4) calc(var(--globals-baseUnit) * 3) 0 calc(var(--globals-baseUnit) * 3);
        margin: 0 0 calc(var(--globals-baseUnit) * 2) 0;
    }

    .contentWrapPadding {
        padding: 0 5.2rem 2rem 2rem
    }

    .contentWrapPaddingFullWidth {
        padding: 0 2rem 2rem 2rem
    }

    .contentWrapPaddingFit {
        padding: 0 calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 1.5);
    }


    .contentWrapSpacious {
        padding: 0 5.2rem 2rem 2rem;

        @media (min-width: 1024px) {
            padding: 0 5.2rem 2.05rem 2rem;
        }
    }

    .contentWrapSpaciousFullWidth {
        padding: 0 2rem 2.95rem 2rem;

        @media (min-width: 1024px) {
            padding: 0 2rem 2.05rem 2rem;
        }
    }

    .contentWrapPaddingMedium {
        padding: 0 calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 5) calc(var(--globals-baseUnit) * 3);
    }
}
@layer packages {
    .headerPaddingDefault {
        padding: 2rem 5.2rem 0 2rem;
        margin: 0 0 1.95rem 0;

        @media (min-width: 1024px) {
            margin: 0 0 0.45rem 0;
        }
    }
    .headerPaddingSpacious {
        padding: 2.95rem 5.2rem 0 2rem;
        margin: 0 0 2.8rem 0;

        @media (min-width: 1024px) {
            padding: 2.05rem 5.2rem 0 2rem;
        }
    }

    .headerPaddingMedium {
        padding: calc(var(--globals-baseUnit) * 4) calc(var(--globals-baseUnit) * 3) 0 calc(var(--globals-baseUnit) * 3);
        margin: 0 0 calc(var(--globals-baseUnit) * 3) 0;
    }

    .headerPaddingFit {
        padding: calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 1.5) 0 calc(var(--globals-baseUnit) * 1.5);
        margin: 0 0 var(--globals-baseUnit) 0;
    }

    .headerPaddingFitGray {
        padding: calc(var(--globals-baseUnit) * 4) calc(var(--globals-baseUnit) * 3) 0 calc(var(--globals-baseUnit) * 3);
        margin: 0 0 calc(var(--globals-baseUnit) * 2) 0;
    }

    .contentWrapPadding {
        padding: 0 5.2rem 2rem 2rem
    }

    .contentWrapPaddingFullWidth {
        padding: 0 2rem 2rem 2rem
    }

    .contentWrapPaddingFit {
        padding: 0 calc(var(--globals-baseUnit) * 1.5) calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 1.5);
    }


    .contentWrapSpacious {
        padding: 0 5.2rem 2rem 2rem;

        @media (min-width: 1024px) {
            padding: 0 5.2rem 2.05rem 2rem;
        }
    }

    .contentWrapSpaciousFullWidth {
        padding: 0 2rem 2.95rem 2rem;

        @media (min-width: 1024px) {
            padding: 0 2rem 2.05rem 2rem;
        }
    }

    .contentWrapPaddingMedium {
        padding: 0 calc(var(--globals-baseUnit) * 3) calc(var(--globals-baseUnit) * 5) calc(var(--globals-baseUnit) * 3);
    }
}
