@layer packages {
    .componentContainerStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelStyle {
        cursor: pointer;
        margin: 0 3.2rem 0 0;
    }

    .switcherStyle {
        position: relative;
        cursor: pointer;
    }

    .switchWrapStyle {
        border-radius: 1.6rem;
        padding: 0.3rem;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        &:focus-visible ~ div {
            outline: 0.2rem solid var(--colors-info);
        }
    }

    .switchStyle {
        cursor: pointer;
        width: 3.6rem;
        height: 1.8rem;
        padding: 0.2rem;
        transition: background, var(--transition-timingFunction), var(--transition-duration);
        border-radius: 1.6rem;
    }

    .switchIndicatorStyle {
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
        transition: transform, var(--transition-timingFunction), var(--transition-duration);
        background: #fff;
    }
}
@layer packages {
    .componentContainerStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelStyle {
        cursor: pointer;
        margin: 0 3.2rem 0 0;
    }

    .switcherStyle {
        position: relative;
        cursor: pointer;
    }

    .switchWrapStyle {
        border-radius: 1.6rem;
        padding: 0.3rem;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        &:focus-visible ~ div {
            outline: 0.2rem solid var(--colors-info);
        }
    }

    .switchStyle {
        cursor: pointer;
        width: 3.6rem;
        height: 1.8rem;
        padding: 0.2rem;
        transition: background, var(--transition-timingFunction), var(--transition-duration);
        border-radius: 1.6rem;
    }

    .switchIndicatorStyle {
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
        transition: transform, var(--transition-timingFunction), var(--transition-duration);
        background: #fff;
    }
}
