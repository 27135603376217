@layer packages {
    .iconPosition {
        position: absolute;
        top: 50%;
        left: calc(var(--globals-baseUnit) * 2);
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 10;
    }

    .iconPositionRight {
        left: auto;
        right: calc(var(--globals-baseUnit) * 2);
    }

    .iconPositionMobile {
        @media (min-width: var(--breakpoints-md)) {
            display: block;
        }
    }

}
@layer packages {
    .iconPosition {
        position: absolute;
        top: 50%;
        left: calc(var(--globals-baseUnit) * 2);
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 10;
    }

    .iconPositionRight {
        left: auto;
        right: calc(var(--globals-baseUnit) * 2);
    }

    .iconPositionMobile {
        @media (min-width: var(--breakpoints-md)) {
            display: block;
        }
    }

}
