@layer packages {
    .message {
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--colors-secondary);
        border-radius: var(--globals-baseUnit);

        &:focus-visible {
            outline: none;
        }
    }

    .messageNeutral {
        background: white;
    }

    .messageAction {
        background: var(--colors-primary);
    }

    .messageSuccess {
        background: var(--colors-success);
    }

    .messageInfo {
        background: var(--colors-info);
    }

    .messageWarning {
        background: var(--colors-warning);
    }

    .messageFailure {
        background: var(--colors-secondary);
    }

    .messageContent {
        padding: calc(var(--globals-baseUnit) * 2);
        display: flex;
        align-items: center;

        &:focus-visible {
            outline: none;
        }
    }

    .messageContentFailure {
        color: white;
    }
}
@layer packages {
    .message {
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--colors-secondary);
        border-radius: var(--globals-baseUnit);

        &:focus-visible {
            outline: none;
        }
    }

    .messageNeutral {
        background: white;
    }

    .messageAction {
        background: var(--colors-primary);
    }

    .messageSuccess {
        background: var(--colors-success);
    }

    .messageInfo {
        background: var(--colors-info);
    }

    .messageWarning {
        background: var(--colors-warning);
    }

    .messageFailure {
        background: var(--colors-secondary);
    }

    .messageContent {
        padding: calc(var(--globals-baseUnit) * 2);
        display: flex;
        align-items: center;

        &:focus-visible {
            outline: none;
        }
    }

    .messageContentFailure {
        color: white;
    }
}
