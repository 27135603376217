@layer packages {
    .col {
        position: relative;
        width: 100%;
        margin-left: 0;
        padding-left: var(--globals-baseUnit);
        padding-right: var(--globals-baseUnit);
        max-width: 100%;

        @media (min-width: 0) {
            margin-left: 0;
            padding-left: calc(var(--grid-sm-gutterPx) / 2);
            padding-right: calc(var(--grid-sm-gutterPx) / 2);
            flex: 1 0 0;
            max-width: 100%;
        }

        @media (min-width: 1024px) {
            margin-left: 0;
            padding-left: calc(var(--grid-md-gutterPx) / 2);
            padding-right: calc(var(--grid-md-gutterPx) / 2);
        }

        @media (min-width: 1366px) {
            margin-left: 0;
            padding-left: calc(var(--grid-lg-gutterPx) / 2);
            padding-right: calc(var(--grid-lg-gutterPx) / 2);
        }
    }

    @media (min-width: 0) {
        /* sm - max 4 columns */
        .colSm1Of4 {
            flex: 1 0 calc(100% / 4 * 1);
            max-width: calc(100% / 4 * 1);
        }

        .colSm2Of4 {
            flex: 1 0 calc(100% / 4 * 2);
            max-width: calc(100% / 4 * 2);
        }

        .colSm3Of4 {
            flex: 1 0 calc(100% / 4 * 3);
            max-width: calc(100% / 4 * 3);
        }

        .colSm4Of4 {
            flex: 1 0 calc(100% / 4 * 4);
            max-width: calc(100% / 4 * 4);
        }

        /* sm offset - max 4 columns */
        .colSmOffset1Of4 {
            margin-left: calc(100% / 4 * 1);
        }

        .colSmOffset2Of4 {
            margin-left: calc(100% / 4 * 2);
        }

        .colSmOffset3Of4 {
            margin-left: calc(100% / 4 * 3);
        }

        .colSmOffset4Of4 {
            margin-left: calc(100% / 4 * 4);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: 1024px) {
        /* md - max 12 columns */
        .colMd1Of12 {
            flex: 1 0 calc(100% / 12 * 1);
            max-width: calc(100% / 12 * 1);
        }

        .colMd2Of12 {
            flex: 1 0 calc(100% / 12 * 2);
            max-width: calc(100% / 12 * 2);
        }

        .colMd3Of12 {
            flex: 1 0 calc(100% / 12 * 3);
            max-width: calc(100% / 12 * 3);
        }

        .colMd4Of12 {
            flex: 1 0 calc(100% / 12 * 4);
            max-width: calc(100% / 12 * 4);
        }

        .colMd5Of12 {
            flex: 1 0 calc(100% / 12 * 5);
            max-width: calc(100% / 12 * 5);
        }

        .colMd6Of12 {
            flex: 1 0 calc(100% / 12 * 6);
            max-width: calc(100% / 12 * 6);
        }

        .colMd7Of12 {
            flex: 1 0 calc(100% / 12 * 7);
            max-width: calc(100% / 12 * 7);
        }

        .colMd8Of12 {
            flex: 1 0 calc(100% / 12 * 8);
            max-width: calc(100% / 12 * 8);
        }

        .colMd9Of12 {
            flex: 1 0 calc(100% / 12 * 9);
            max-width: calc(100% / 12 * 9);
        }

        .colMd10Of12 {
            flex: 1 0 calc(100% / 12 * 10);
            max-width: calc(100% / 12 * 10);
        }

        .colMd11Of12 {
            flex: 1 0 calc(100% / 12 * 11);
            max-width: calc(100% / 12 * 11);
        }

        .colMd12Of12 {
            flex: 1 0 calc(100% / 12 * 12);
            max-width: calc(100% / 12 * 12);
        }

        /* md offset - max 12 columns */
        .colMdOffset1Of12 {
            margin-left: calc(100% / 12 * 1);
        }

        .colMdOffset2Of12 {
            margin-left: calc(100% / 12 * 2);
        }

        .colMdOffset3Of12 {
            margin-left: calc(100% / 12 * 3);
        }

        .colMdOffset4Of12 {
            margin-left: calc(100% / 12 * 4);
        }

        .colMdOffset5Of12 {
            margin-left: calc(100% / 12 * 5);
        }

        .colMdOffset6Of12 {
            margin-left: calc(100% / 12 * 6);
        }

        .colMdOffset7Of12 {
            margin-left: calc(100% / 12 * 7);
        }

        .colMdOffset8Of12 {
            margin-left: calc(100% / 12 * 8);
        }

        .colMdOffset9Of12 {
            margin-left: calc(100% / 12 * 9);
        }

        .colMdOffset10Of12 {
            margin-left: calc(100% / 12 * 10);
        }

        .colMdOffset11Of12 {
            margin-left: calc(100% / 12 * 11);
        }

        .colMdOffset12Of12 {
            margin-left: calc(100% / 12 * 12);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: 1366px) {
        /* lg - max 16 columns */
        .colLg1Of16 {
            flex: 1 0 calc(100% / 16 * 1);
            max-width: calc(100% / 16 * 1);
        }

        .colLg2Of16 {
            flex: 1 0 calc(100% / 16 * 2);
            max-width: calc(100% / 16 * 2);
        }

        .colLg3Of16 {
            flex: 1 0 calc(100% / 16 * 3);
            max-width: calc(100% / 16 * 3);
        }

        .colLg4Of16 {
            flex: 1 0 calc(100% / 16 * 4);
            max-width: calc(100% / 16 * 4);
        }

        .colLg5Of16 {
            flex: 1 0 calc(100% / 16 * 5);
            max-width: calc(100% / 16 * 5);
        }

        .colLg6Of16 {
            flex: 1 0 calc(100% / 16 * 6);
            max-width: calc(100% / 16 * 6);
        }

        .colLg7Of16 {
            flex: 1 0 calc(100% / 16 * 7);
            max-width: calc(100% / 16 * 7);
        }

        .colLg8Of16 {
            flex: 1 0 calc(100% / 16 * 8);
            max-width: calc(100% / 16 * 8);
        }

        .colLg9Of16 {
            flex: 1 0 calc(100% / 16 * 9);
            max-width: calc(100% / 16 * 9);
        }

        .colLg10Of16 {
            flex: 1 0 calc(100% / 16 * 10);
            max-width: calc(100% / 16 * 10);
        }

        .colLg11Of16 {
            flex: 1 0 calc(100% / 16 * 11);
            max-width: calc(100% / 16 * 11);
        }

        .colLg12Of16 {
            flex: 1 0 calc(100% / 16 * 12);
            max-width: calc(100% / 16 * 12);
        }

        .colLg13Of16 {
            flex: 1 0 calc(100% / 16 * 13);
            max-width: calc(100% / 16 * 13);
        }

        .colLg14Of16 {
            flex: 1 0 calc(100% / 16 * 14);
            max-width: calc(100% / 16 * 14);
        }

        .colLg15Of16 {
            flex: 1 0 calc(100% / 16 * 15);
            max-width: calc(100% / 16 * 15);
        }

        .colLg16Of16 {
            flex: 1 0 calc(100% / 16 * 16);
            max-width: calc(100% / 16 * 16);
        }

        /* lg offset - max 16 columns */
        .colLgOffset1Of16 {
            margin-left: calc(100% / 16 * 1);
        }

        .colLgOffset2Of16 {
            margin-left: calc(100% / 16 * 2);
        }

        .colLgOffset3Of16 {
            margin-left: calc(100% / 16 * 3);
        }

        .colLgOffset4Of16 {
            margin-left: calc(100% / 16 * 4);
        }

        .colLgOffset5Of16 {
            margin-left: calc(100% / 16 * 5);
        }

        .colLgOffset6Of16 {
            margin-left: calc(100% / 16 * 6);
        }

        .colLgOffset7Of16 {
            margin-left: calc(100% / 16 * 7);
        }

        .colLgOffset8Of16 {
            margin-left: calc(100% / 16 * 8);
        }

        .colLgOffset9Of16 {
            margin-left: calc(100% / 16 * 9);
        }

        .colLgOffset10Of16 {
            margin-left: calc(100% / 16 * 10);
        }

        .colLgOffset11Of16 {
            margin-left: calc(100% / 16 * 11);
        }

        .colLgOffset12Of16 {
            margin-left: calc(100% / 16 * 12);
        }

        .colLgOffset13Of16 {
            margin-left: calc(100% / 16 * 13);
        }

        .colLgOffset14Of16 {
            margin-left: calc(100% / 16 * 14);
        }

        .colLgOffset15Of16 {
            margin-left: calc(100% / 16 * 15);
        }

        .colLgOffset16Of16 {
            margin-left: calc(100% / 16 * 16);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
@layer packages {
    .col {
        position: relative;
        width: 100%;
        margin-left: 0;
        padding-left: var(--globals-baseUnit);
        padding-right: var(--globals-baseUnit);
        max-width: 100%;

        @media (min-width: 0) {
            margin-left: 0;
            padding-left: calc(var(--grid-sm-gutterPx) / 2);
            padding-right: calc(var(--grid-sm-gutterPx) / 2);
            flex: 1 0 0;
            max-width: 100%;
        }

        @media (min-width: 1024px) {
            margin-left: 0;
            padding-left: calc(var(--grid-md-gutterPx) / 2);
            padding-right: calc(var(--grid-md-gutterPx) / 2);
        }

        @media (min-width: 1366px) {
            margin-left: 0;
            padding-left: calc(var(--grid-lg-gutterPx) / 2);
            padding-right: calc(var(--grid-lg-gutterPx) / 2);
        }
    }

    @media (min-width: 0) {
        /* sm - max 4 columns */
        .colSm1Of4 {
            flex: 1 0 calc(100% / 4 * 1);
            max-width: calc(100% / 4 * 1);
        }

        .colSm2Of4 {
            flex: 1 0 calc(100% / 4 * 2);
            max-width: calc(100% / 4 * 2);
        }

        .colSm3Of4 {
            flex: 1 0 calc(100% / 4 * 3);
            max-width: calc(100% / 4 * 3);
        }

        .colSm4Of4 {
            flex: 1 0 calc(100% / 4 * 4);
            max-width: calc(100% / 4 * 4);
        }

        /* sm offset - max 4 columns */
        .colSmOffset1Of4 {
            margin-left: calc(100% / 4 * 1);
        }

        .colSmOffset2Of4 {
            margin-left: calc(100% / 4 * 2);
        }

        .colSmOffset3Of4 {
            margin-left: calc(100% / 4 * 3);
        }

        .colSmOffset4Of4 {
            margin-left: calc(100% / 4 * 4);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: 1024px) {
        /* md - max 12 columns */
        .colMd1Of12 {
            flex: 1 0 calc(100% / 12 * 1);
            max-width: calc(100% / 12 * 1);
        }

        .colMd2Of12 {
            flex: 1 0 calc(100% / 12 * 2);
            max-width: calc(100% / 12 * 2);
        }

        .colMd3Of12 {
            flex: 1 0 calc(100% / 12 * 3);
            max-width: calc(100% / 12 * 3);
        }

        .colMd4Of12 {
            flex: 1 0 calc(100% / 12 * 4);
            max-width: calc(100% / 12 * 4);
        }

        .colMd5Of12 {
            flex: 1 0 calc(100% / 12 * 5);
            max-width: calc(100% / 12 * 5);
        }

        .colMd6Of12 {
            flex: 1 0 calc(100% / 12 * 6);
            max-width: calc(100% / 12 * 6);
        }

        .colMd7Of12 {
            flex: 1 0 calc(100% / 12 * 7);
            max-width: calc(100% / 12 * 7);
        }

        .colMd8Of12 {
            flex: 1 0 calc(100% / 12 * 8);
            max-width: calc(100% / 12 * 8);
        }

        .colMd9Of12 {
            flex: 1 0 calc(100% / 12 * 9);
            max-width: calc(100% / 12 * 9);
        }

        .colMd10Of12 {
            flex: 1 0 calc(100% / 12 * 10);
            max-width: calc(100% / 12 * 10);
        }

        .colMd11Of12 {
            flex: 1 0 calc(100% / 12 * 11);
            max-width: calc(100% / 12 * 11);
        }

        .colMd12Of12 {
            flex: 1 0 calc(100% / 12 * 12);
            max-width: calc(100% / 12 * 12);
        }

        /* md offset - max 12 columns */
        .colMdOffset1Of12 {
            margin-left: calc(100% / 12 * 1);
        }

        .colMdOffset2Of12 {
            margin-left: calc(100% / 12 * 2);
        }

        .colMdOffset3Of12 {
            margin-left: calc(100% / 12 * 3);
        }

        .colMdOffset4Of12 {
            margin-left: calc(100% / 12 * 4);
        }

        .colMdOffset5Of12 {
            margin-left: calc(100% / 12 * 5);
        }

        .colMdOffset6Of12 {
            margin-left: calc(100% / 12 * 6);
        }

        .colMdOffset7Of12 {
            margin-left: calc(100% / 12 * 7);
        }

        .colMdOffset8Of12 {
            margin-left: calc(100% / 12 * 8);
        }

        .colMdOffset9Of12 {
            margin-left: calc(100% / 12 * 9);
        }

        .colMdOffset10Of12 {
            margin-left: calc(100% / 12 * 10);
        }

        .colMdOffset11Of12 {
            margin-left: calc(100% / 12 * 11);
        }

        .colMdOffset12Of12 {
            margin-left: calc(100% / 12 * 12);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: 1366px) {
        /* lg - max 16 columns */
        .colLg1Of16 {
            flex: 1 0 calc(100% / 16 * 1);
            max-width: calc(100% / 16 * 1);
        }

        .colLg2Of16 {
            flex: 1 0 calc(100% / 16 * 2);
            max-width: calc(100% / 16 * 2);
        }

        .colLg3Of16 {
            flex: 1 0 calc(100% / 16 * 3);
            max-width: calc(100% / 16 * 3);
        }

        .colLg4Of16 {
            flex: 1 0 calc(100% / 16 * 4);
            max-width: calc(100% / 16 * 4);
        }

        .colLg5Of16 {
            flex: 1 0 calc(100% / 16 * 5);
            max-width: calc(100% / 16 * 5);
        }

        .colLg6Of16 {
            flex: 1 0 calc(100% / 16 * 6);
            max-width: calc(100% / 16 * 6);
        }

        .colLg7Of16 {
            flex: 1 0 calc(100% / 16 * 7);
            max-width: calc(100% / 16 * 7);
        }

        .colLg8Of16 {
            flex: 1 0 calc(100% / 16 * 8);
            max-width: calc(100% / 16 * 8);
        }

        .colLg9Of16 {
            flex: 1 0 calc(100% / 16 * 9);
            max-width: calc(100% / 16 * 9);
        }

        .colLg10Of16 {
            flex: 1 0 calc(100% / 16 * 10);
            max-width: calc(100% / 16 * 10);
        }

        .colLg11Of16 {
            flex: 1 0 calc(100% / 16 * 11);
            max-width: calc(100% / 16 * 11);
        }

        .colLg12Of16 {
            flex: 1 0 calc(100% / 16 * 12);
            max-width: calc(100% / 16 * 12);
        }

        .colLg13Of16 {
            flex: 1 0 calc(100% / 16 * 13);
            max-width: calc(100% / 16 * 13);
        }

        .colLg14Of16 {
            flex: 1 0 calc(100% / 16 * 14);
            max-width: calc(100% / 16 * 14);
        }

        .colLg15Of16 {
            flex: 1 0 calc(100% / 16 * 15);
            max-width: calc(100% / 16 * 15);
        }

        .colLg16Of16 {
            flex: 1 0 calc(100% / 16 * 16);
            max-width: calc(100% / 16 * 16);
        }

        /* lg offset - max 16 columns */
        .colLgOffset1Of16 {
            margin-left: calc(100% / 16 * 1);
        }

        .colLgOffset2Of16 {
            margin-left: calc(100% / 16 * 2);
        }

        .colLgOffset3Of16 {
            margin-left: calc(100% / 16 * 3);
        }

        .colLgOffset4Of16 {
            margin-left: calc(100% / 16 * 4);
        }

        .colLgOffset5Of16 {
            margin-left: calc(100% / 16 * 5);
        }

        .colLgOffset6Of16 {
            margin-left: calc(100% / 16 * 6);
        }

        .colLgOffset7Of16 {
            margin-left: calc(100% / 16 * 7);
        }

        .colLgOffset8Of16 {
            margin-left: calc(100% / 16 * 8);
        }

        .colLgOffset9Of16 {
            margin-left: calc(100% / 16 * 9);
        }

        .colLgOffset10Of16 {
            margin-left: calc(100% / 16 * 10);
        }

        .colLgOffset11Of16 {
            margin-left: calc(100% / 16 * 11);
        }

        .colLgOffset12Of16 {
            margin-left: calc(100% / 16 * 12);
        }

        .colLgOffset13Of16 {
            margin-left: calc(100% / 16 * 13);
        }

        .colLgOffset14Of16 {
            margin-left: calc(100% / 16 * 14);
        }

        .colLgOffset15Of16 {
            margin-left: calc(100% / 16 * 15);
        }

        .colLgOffset16Of16 {
            margin-left: calc(100% / 16 * 16);
        }

        .colNoGutters {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
