.optionWrapperStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: var(--globals-baseUnit);
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(174, 177, 180, 0.3);
}

.optionElementStyle {
    padding: var(--globals-baseUnit) calc(2 * var(--globals-baseUnit));
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
}

.optionElementStyle:hover {
    background: #f2f2f2;
}
.optionWrapperStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: var(--globals-baseUnit);
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(174, 177, 180, 0.3);
}

.optionElementStyle {
    padding: var(--globals-baseUnit) calc(2 * var(--globals-baseUnit));
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
}

.optionElementStyle:hover {
    background: #f2f2f2;
}
