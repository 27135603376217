.noOptionsMessage {
    padding: calc(2 * var(--globals-baseUnit));
    border-radius: var(--globals-baseUnit);
    background-color: var(--colors-gray-200);
}
.noOptionsMessage {
    padding: calc(2 * var(--globals-baseUnit));
    border-radius: var(--globals-baseUnit);
    background-color: var(--colors-gray-200);
}
