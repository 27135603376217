@layer packages {
    .singleSlide {
        --minWidth-slide: 100%;
        min-width: var(--minWidth-slide);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
    }

    .singleSlide picture {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .singleSlide picture img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .galleryHolder {
        position: relative;
        margin: 0 -0.1rem;
        touch-action: pan-y pinch-zoom;
        height: 100%;
        width: 100%;

        &:hover {
            .arrow-holder {
                opacity: 1;
            }
        }
    }

    .slider {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .sliderInnerWrap {
        height: 100%;
        overflow: hidden;
    }
}
@layer packages {
    .singleSlide {
        --minWidth-slide: 100%;
        min-width: var(--minWidth-slide);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
    }

    .singleSlide picture {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .singleSlide picture img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .galleryHolder {
        position: relative;
        margin: 0 -0.1rem;
        touch-action: pan-y pinch-zoom;
        height: 100%;
        width: 100%;

        &:hover {
            .arrow-holder {
                opacity: 1;
            }
        }
    }

    .slider {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .sliderInnerWrap {
        height: 100%;
        overflow: hidden;
    }
}
