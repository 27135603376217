.rangeHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
}

.separator {
    margin-bottom: 1rem;

}

.inputHolder {
    display: inline-flex;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
}
.rangeHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
}

.separator {
    margin-bottom: 1rem;

}

.inputHolder {
    display: inline-flex;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
}
