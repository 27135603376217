@layer packages {
    .row {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin: 0 0 0 var(--globals-baseUnit);

        @media (min-width: 0) {
            margin: 0 calc(var(--grid-sm-gutterPx) / -2);
        }

        @media (min-width: 1024px) {
            margin: 0 calc(var(--grid-md-gutterPx) / -2);
        }

        @media (min-width: 1366px) {
            margin: 0 calc(var(--grid-lg-gutterPx) / -2);
        }
    }
}
@layer packages {
    .row {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin: 0 0 0 var(--globals-baseUnit);

        @media (min-width: 0) {
            margin: 0 calc(var(--grid-sm-gutterPx) / -2);
        }

        @media (min-width: 1024px) {
            margin: 0 calc(var(--grid-md-gutterPx) / -2);
        }

        @media (min-width: 1366px) {
            margin: 0 calc(var(--grid-lg-gutterPx) / -2);
        }
    }
}
