@layer packages {
    .checkboxList {
        padding: 0;
        list-style: none;
        display: flex;
        font-size: 1.2rem;
        width: 100%;
        align-items: stretch;
        border: 1px solid var(--colors-gray-600);
        border-radius: var(--globals-baseUnit);
        overflow: hidden;
        word-wrap: normal;
    }

    .checkboxListField {
        height: 4.8rem;
        min-height: 4.8rem;
    }

    .checkboxHolder {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1;
        border-right: 1px solid var(--colors-gray-600);
        transition: box-shadow 0.2s ease-out;
        cursor: pointer;

        &:last-of-type {
            border-right: none;
        }

        @media (min-width: 1024px) {
            font-size: 1.4rem;

            &:not(.checkboxHolderDisabled):hover {
                box-shadow: inset 0 0 0 500px rgba(from var(--colors-gray-600) R G B / 0.3);
            }
        }
    }

    .checkboxHolderDisabled {
        cursor: not-allowed;
        color: #ccc;
    }

    .checkboxHolderActive {
        background: var(--colors-primary);
    }

    .checkboxHolderInactive {
        background: #ececec;

    }
}
@layer packages {
    .checkboxList {
        padding: 0;
        list-style: none;
        display: flex;
        font-size: 1.2rem;
        width: 100%;
        align-items: stretch;
        border: 1px solid var(--colors-gray-600);
        border-radius: var(--globals-baseUnit);
        overflow: hidden;
        word-wrap: normal;
    }

    .checkboxListField {
        height: 4.8rem;
        min-height: 4.8rem;
    }

    .checkboxHolder {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1;
        border-right: 1px solid var(--colors-gray-600);
        transition: box-shadow 0.2s ease-out;
        cursor: pointer;

        &:last-of-type {
            border-right: none;
        }

        @media (min-width: 1024px) {
            font-size: 1.4rem;

            &:not(.checkboxHolderDisabled):hover {
                box-shadow: inset 0 0 0 500px rgba(from var(--colors-gray-600) R G B / 0.3);
            }
        }
    }

    .checkboxHolderDisabled {
        cursor: not-allowed;
        color: #ccc;
    }

    .checkboxHolderActive {
        background: var(--colors-primary);
    }

    .checkboxHolderInactive {
        background: #ececec;

    }
}
