@layer packages {
    .wrapper {
        padding: 0;
    }

    .innerWrapper {
        display: flex;
        width: 100%;
        flex-basis: 100%;
    }

    .selectStyle {
        min-width: 14rem;
        border: none;
        z-index: 10000;
    }

    .inputStyle {
        width: 100%;
        flex-basis: 100%;
        -moz-appearance: textfield;
    }

    /*
    * remove browser specific number selection arrows as they make no sense in a phone input
    */
    .inputStyle::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
@layer packages {
    .wrapper {
        padding: 0;
    }

    .innerWrapper {
        display: flex;
        width: 100%;
        flex-basis: 100%;
    }

    .selectStyle {
        min-width: 14rem;
        border: none;
        z-index: 10000;
    }

    .inputStyle {
        width: 100%;
        flex-basis: 100%;
        -moz-appearance: textfield;
    }

    /*
    * remove browser specific number selection arrows as they make no sense in a phone input
    */
    .inputStyle::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
