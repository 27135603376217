@layer packages {
    .checkboxFrame {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .checkboxFrameSvg {
            width: 100%;
            height: 100%;
            transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

    .checkboxWrapStyle {
        border-radius: 0.4rem;
    }

    .checkboxCheckedMark {
        --opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        opacity: var(--opacity);
        transition: opacity, var(--transition-timingFunction), var(--transition-duration);
    }
    .checkboxCheckedMarkSvg {
            width: 100%;
            height: 100%;
            transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

}
@layer packages {
    .checkboxFrame {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .checkboxFrameSvg {
            width: 100%;
            height: 100%;
            transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

    .checkboxWrapStyle {
        border-radius: 0.4rem;
    }

    .checkboxCheckedMark {
        --opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        opacity: var(--opacity);
        transition: opacity, var(--transition-timingFunction), var(--transition-duration);
    }
    .checkboxCheckedMarkSvg {
            width: 100%;
            height: 100%;
            transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

}
