@layer packages {
    .aplaBase {
        display: inline-block;
        position: relative;
    }

    .aplaBase:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
    }

    .aplaDark::after {
        background: linear-gradient(360deg, #23232d 0%, rgba(255, 255, 255, 0) 100%);
    }

    .aplaLight::after {
        background: linear-gradient(360deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
    }

    .aplaWhite::after {
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    .aplaContent {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
    }
}

@layer packages {
    .aplaBase {
        display: inline-block;
        position: relative;
    }

    .aplaBase:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
    }

    .aplaDark::after {
        background: linear-gradient(360deg, #23232d 0%, rgba(255, 255, 255, 0) 100%);
    }

    .aplaLight::after {
        background: linear-gradient(360deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
    }

    .aplaWhite::after {
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    .aplaContent {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
    }
}

