.wrapperStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--globals-baseUnit) * 3);
    height: calc(var(--globals-baseUnit) * 3);
    border-radius: calc(var(--globals-baseUnit) * 1.5);
    background-color: var(--colors-primary);
    border: 1px solid var(--colors-secondary);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.wrapperStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--globals-baseUnit) * 3);
    height: calc(var(--globals-baseUnit) * 3);
    border-radius: calc(var(--globals-baseUnit) * 1.5);
    background-color: var(--colors-primary);
    border: 1px solid var(--colors-secondary);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
