
.rangeHolder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
}

.inputHolder {
    display: inline-flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
}

.separatorStyle {
    padding-top: calc(1.5 * var(--globals-baseUnit));
    padding-bottom: calc(1.5 * var(--globals-baseUnit));
    margin-bottom: 1rem;
}

.inputOptionsWrapper {
    background: #fff;
    /*2 to display over other normal elements*/
    z-index: 2;
}

.displayNone {
    display: none;
}

.rangeHolder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
}

.inputHolder {
    display: inline-flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
}

.separatorStyle {
    padding-top: calc(1.5 * var(--globals-baseUnit));
    padding-bottom: calc(1.5 * var(--globals-baseUnit));
    margin-bottom: 1rem;
}

.inputOptionsWrapper {
    background: #fff;
    /*2 to display over other normal elements*/
    z-index: 2;
}

.displayNone {
    display: none;
}
