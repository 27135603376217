.inputBase {
    cursor: text;
}

.inputBaseDisabled {
    cursor: inherit;
}
.inputBase {
    cursor: text;
}

.inputBaseDisabled {
    cursor: inherit;
}
