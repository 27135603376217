.simpleSliderWrapper {
    position: relative;
}

.simpleSliderContainer {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.simpleSliderSlide {
    position: relative;
    flex: 0 0 auto;
}

.simpleSliderArrowWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.simpleSliderArrowWrapperLeft {
    left: 1.2rem;
}

.simpleSliderArrowWrapperRight {
    right: 1.2rem;
}
.simpleSliderWrapper {
    position: relative;
}

.simpleSliderContainer {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.simpleSliderSlide {
    position: relative;
    flex: 0 0 auto;
}

.simpleSliderArrowWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.simpleSliderArrowWrapperLeft {
    left: 1.2rem;
}

.simpleSliderArrowWrapperRight {
    right: 1.2rem;
}
