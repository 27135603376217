@layer packages {
    .componentContainerStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelStyle {
        margin: 0 0 0 0.4rem;
        display: flex;
        flex: 1 0;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }

    .errorLabelStyle {
        color: var(--colors-danger);
    }

    .labelStyle[aria-disabled="true"] {
        color: var(--colors-gray-500);
        cursor: not-allowed;
    }

    .checkboxWrapperStyle {
        position: relative;
        cursor: pointer;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 20;

        & ~ div {
            outline: 0.2rem solid transparent;
            transition: outline-color, var(--transition-timingFunction), var(--transition-duration);
        }

        &:focus-visible ~ div {
            outline-color: var(--colors-info);
        }

        &:disabled {
            cursor: not-allowed;
            color: var(--colors-gray-500);


            & ~ div svg {
                fill: var(--colors-gray-300);
            }
        }
    }
}
@layer packages {
    .componentContainerStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelStyle {
        margin: 0 0 0 0.4rem;
        display: flex;
        flex: 1 0;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }

    .errorLabelStyle {
        color: var(--colors-danger);
    }

    .labelStyle[aria-disabled="true"] {
        color: var(--colors-gray-500);
        cursor: not-allowed;
    }

    .checkboxWrapperStyle {
        position: relative;
        cursor: pointer;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 20;

        & ~ div {
            outline: 0.2rem solid transparent;
            transition: outline-color, var(--transition-timingFunction), var(--transition-duration);
        }

        &:focus-visible ~ div {
            outline-color: var(--colors-info);
        }

        &:disabled {
            cursor: not-allowed;
            color: var(--colors-gray-500);


            & ~ div svg {
                fill: var(--colors-gray-300);
            }
        }
    }
}
